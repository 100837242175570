// Override constants for Paytm here
export const PLATFORM = "paytm";
export const PICKS_CAROUSEL_TITLE = "Magazine";
export const SITE = "Paytm";
export const allowFullScreenVideo = false;
export const paytmTagId = "58217c208d44ae42076f529b";
export const devicePlatform = "paytm";
export const minAppVersion = "1.1";
export const disabledBanners = [];
export * from "./common";
