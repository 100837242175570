import * as React from "react";
import { useEffect } from "react";
import { goToMobileCitySelector } from "./common";
const AppBar = props => {
    const { lockBodyScroll } = props;
    useEffect(() => {
        goToMobileCitySelector(props);
    });
    useEffect(() => {
        if (lockBodyScroll) {
            document.documentElement.classList.add("no-scroll");
        }
        else {
            document.documentElement.classList.remove("no-scroll");
        }
    }, [lockBodyScroll]);
    return <React.Fragment />;
};
export default AppBar;
