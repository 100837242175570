import { generateH5Email } from "./index";
const WEB_SOURCE_KEY = "Web source";
export function sendClevertapEvent(event, payload) {
    try {
        if (window.clevertap && clevertap.event) {
            clevertap.event.push(`H5: ${event}`, Object.assign(Object.assign({}, payload), { [WEB_SOURCE_KEY]: "H5" }));
        }
    }
    catch (e) {
        // Fall through
    }
}
export function sendClevertapOnUserLoginEvent(userData, payload) {
    try {
        if (window.clevertap && clevertap.onUserLogin && clevertap.event) {
            clevertap.onUserLogin.push({
                Site: {
                    Email: generateH5Email(userData.customerId),
                    Identity: userData._id,
                    [WEB_SOURCE_KEY]: "H5"
                }
            });
            clevertap.event.push("H5: Login/Signup Successful", Object.assign(Object.assign({}, payload), { url: window.location.pathname, [WEB_SOURCE_KEY]: "H5" }));
        }
    }
    catch (e) {
        // Fall through
    }
}
export function updateClevertapUserProfile(payload) {
    try {
        if (window.clevertap && clevertap.profile) {
            clevertap.profile.push({
                Site: payload
            });
        }
    }
    catch (e) {
        // Fall through	
    }
}
